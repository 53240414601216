export const generalApi = {
  GetGameNavigationList: "/api/General/getGameNavigationList", //全部游戏
  GetGamePopular: "/api/General/getGamePopular", //热门游戏列表
  GetFavorites: "/api/General/getFavorites", //收藏游戏列表
  AddFavorites: "/api/General/addFavorites", //收藏游戏
  DeleteFavorites: "/api/General/deleteFavorites", //取消收藏游戏
  GetGameBigType: "/api/General/getGameBigType", //返利分类导航
  GetLanguageList: "/api/General/getLanguageList", //获取语言列表
  CheckSystemStatus: "/api/General/checkSystemStatus",
};

export const PublicityApi = {
  GetList: "/api/publicity/getList",
};

export const GameApi = {
  GetList: "/api/Game/getList",
  GetListByShowCategory: "/api/Game/getListByShowCategory", //1捕鱼,2棋牌,3电子,4视讯类列表
};

export const GamePlatformApi = {
  GetAllList: "/api/GamePlatform/getAllList",
  GetList: "/api/GamePlatform/getList",
};

export const SportServiceApi = {
  GetSportList: "/api/SportService/getSportList", //球种列表
  SportOrderList: "/api/SportService/orderList", // 体育注单列表
  SportPlatform: "/api/SportService/sportPlatform", // 注单筛选使用
  SportPlatformList: "/api/SportService/sportPlatformList", //体育游戏列表
  OrderStatusList: "/api/SportService/statusList",
  MinimumAccessAmount: "/api/SportService/minimumAccessAmount",
};

export const LotteryApi = {
  GetLotteryByCategory: "/api/LotteryCategory/getLotteryByCategory",
  GetLotteryCategory: "/api/LotteryCategory/getLotteryCategory",
  GetLotteryList: "/api/LotteryCategory/getLotteryList", //彩票游戏列表
  GetCategoryDisplayList: "/api/LotteryCategory/getCategoryDisplayList", //彩票平台分类
  GetOrderDetail: "/api/LotteryOrder/getDetail",
  GetOrderList: "/api/LotteryOrder/getList",
  GetOrderStatusList: "/api/LotteryOrder/getOrderStatusList",
  CancelOrder: "/api/LotteryOrder/retract",
};

export const UserVipConfigApi = {
  GetList: "/api/UserVipConfig/getList", //vip列表
  GetUserVipInfo: "/api/UserVipConfig/getUserVipInfo", //用户vip信息
  GetVipRule: "/api/UserVipConfig/getVipRule", //规则
  GetReward: "/api/UserVipConfig/getReward", //领取奖金
  GetUserRebate: "/api/UserVipConfig/getUserRebate", //用户返水列表
  GetRebateDetail: "/api/UserVipConfig/getRebateDetail", //用户返水详情
  GetRebateByCategory: "/api/UserVipConfig/getRebateByCategory", //返利比例
  CashBackByCurrency: "/api/UserVipConfig/cashBackByCurrency", //领取返利
  GetUserRebateRecord: "/api/UserVipConfig/getUserRebateRecord", //返利记录
  GetBonusList: "/api/UserVipConfig/getBonusList", //vip积分表
  RebateSwitch: "/api/UserVipConfig/rebateSwitch",
};
export const SafetyCenterApi = {
  BindThird: "/api/SafetyCenter/bind",
  CheckWithdrawPIN: "/api/SafetyCenter/checkWithdrawPIN",
  GetBindList: "/api/SafetyCenter/getOauth",
  GetInformation: "/api/SafetyCenter/getInformation",
  GetMenu: "/api/SafetyCenter/getMenu",
  GetTrustDevices: "/api/SafetyCenter/getTrustDevices",
  GetWithdraws: "/api/SafetyCenter/getWithdraws",
  InsertPix: "/api/SafetyCenter/insertAlipay",
  InsertTrustDevice: "/api/SafetyCenter/insertTrustDevice",
  RemoveTrustDevice: "/api/SafetyCenter/removeTrustDevice",
  SetRealName: "/api/SafetyCenter/setRealName",
  UpdateMobile: "/api/SafetyCenter/updateMobile",
  UpdatePassword: "/api/SafetyCenter/updatePassword",
  UpdateWithdrawPIN: "/api/SafetyCenter/updateWithdrawPIN",
};

export const AnnouncementApi = {
  GetList: "/api/Announcement/getList", //公告列表
};

export const MessageApi = {
  GetList: "/api/Message/getList", //站内信列表
  GetDetail: "/api/Message/getDetail", //查看消息
  GetUnread: "/api/Message/getUnread", //未读消息数量
};

export const GameUserApi = {
  checkSwitchAmount: "/api/GameUser/checkSwitchAmount",
  EditUserAutoSwitch: "/api/GameUser/editUserAutoSwitch",
  GetAllBalance: "/api/GameUser/getAllBalance",
  GetBetRecord: "/api/GameUser/getBetRecord",
  GetGameUrl: "/api/GameUser/getGameUrl", //获取游戏地址
  RefreshBalance: "/api/GameUser/refreshBalance",
  SwitchAllAmount: "/api/GameUser/switchAllAmount", //提出游戏时拉回余额
  SwitchAmount: "/api/GameUser/switchAmount",
};

export const RedeemCodeApi = {
  Reward: "/api/RedeemCode/reward", //兑换码领取
};

export const BrandApi = {
  GetBrandAboutUs: "/api/Brand/getBrandAboutUs",
  GetBrandActivate: "/api/Brand/getBrandActivate",
  GetBrandLogo: "/api/Brand/getBrandLogo", //获取logo
  GetBrandSignUp: "/api/Brand/getBrandSignUp",
  GetBrandPopUpAds: "/api/Brand/getBrandPopUpAds", //静态广告
  GetBrandPWA192: "/api/Brand/getBrandPWA512",
};

export const ActivityApi = {
  GetActivityInfo: "/api/Activity/getActivityInfo", //活动内容详情
  ClaimedByRecordId: "/api/Activity/claimedByRecordId", //活动内容详情
  ClaimedAll: "/api/Activity/claimedAll", //活动批量
  ActivityCategory: "/api/Activity/getActivityCategory",
  ActivityList: "/api/Activity/getActivityHome",
  ActivityInfo: "/api/Activity/getActivityInfo",
  GetFirstRechargeDiscountList: "/api/Activity/getFirstRechargeDiscountList",
  GetFirstRechargeDiscountPopUp: "/api/Activity/getFirstRechargeDiscountPopUp",
  getActivityDiscountStatus: "/api/Activity/getActivityDiscountStatus",
};

export const ChallengeApi = {
  GetCheckInList: "/api/Challenge/getCheckInList", //每日签到列表
  isAlreadyCheckIn: "/api/Challenge/isAlreadyCheckIn", //今日签到状态
  DailyCheckInByChallenge: "/api/Challenge/dailyCheckInByChallenge", //签到
  GetChallengeCategory: "/api/Challenge/getChallengeCategory", //任务类型列表
  GetChallengeListByCategory: "/api/Challenge/getChallengeListByCategory", //每日、每週、限時任务列表
  GetLivelinessInfo: "/api/Challenge/getLivelinessInfo", //活跃度宝箱
  ClaimedByRecordId: "/api/Challenge/claimedByRecordId", //领取任务奖励（单个）
  ClaimedAll: "/api/Challenge/claimedAll", //一键领取所有任务奖励
  GetBonusByLiveliness: "/api/Challenge/getBonusByLiveliness", //领取活跃度宝箱
  GetDiscountRecordForChallenge: "/api/Challenge/getDiscountRecordForChallenge", //任务奖励领取记录
};

export const RouletteApi = {
  GetHome: "/api/Roulette/getHome", //转盘名称，规则
  GetInfo: "/api/Roulette/getInfo", //转盘信息
  ShareUrl: "/api/Roulette/shareUrl", //获取分享链接
  Start: "/api/Roulette/start", //旋转转盘
  Claimed: "/api/Roulette/claimed", //领取
  HomeFloating: "/api/Roulette/homeFloating", //悬浮窗及弹窗显示状态
};

const agentApi = {
  Apply: "/api/Agent/apply", //申请成为代理
  BecomeAgent: "/api/Agent/becomeAgent",
  InvitationCode: "/api/Agent/getInvitationCode", //获取邀请链接
  InvitationApps: "/api/Agent/getPromotionApps", //推广软件列表
  PublicitySetting: "/api/Agent/getPublicitySetting", //代理主页-查看更多
  AgentRules: "/api/Agent/getAgentRules", //代理规则说明
  AgentDistributionRule: "/api/Agent/getAgentDistributionRule", //佣金比例表
  DistributionRebateStatistics: "/api/Agent/getDistributionRebateStatistics",
  RebateRecordList: "/api/Agent/getRebateRecordList", //佣金记录
  RebateDetail: "/api/Agent/getRebateRecordDetail", //佣金详情
  MemberList: "/api/Agent/getTeamList",
};

const fundsApi = {
  ApplyWithdraw: "/api/Withdraw/applyWithdraw",
  GetPixList: "/api/Account/getAlipay",
  WithdrawConfig: "/api/Withdraw/getWithdrawConfig",
  WithdrawInfo: "/api/User/getWithdrawInfo",
  UpdatePayment: "/api/Account/updatePayment",
  WithdrawRecord: "/api/Account/withdrawRecords",
  AuditRecord: "/api/Withdraw/auditRecord",
  GetMerchantList: "/api/Pay/getMerchantList",
  GetRechargeGroup: "/api/Pay/getRechargeGroup",
  IsFirstRecharge: "/api/Pay/isFirstRecharge",
  OnlineRecharge: "/api/Pay/onlineRecharge",
  RechargeRecord: "/api/Account/rechargeRecords",
};

const userApi = {
  CaptchaCheck: "/api/General/captchaCheck",
  GetCaptcha: "/api/General/captcha",
  DetailRecords: "/api/Account/detailRecords",
  DetailTypes: "/api/Account/detailTypes",
  NBEditUserInfo: "/api/Account/editUserInfo",
  FundsCheck: "/api/User/fundsCheck",
  GetRegisterConfig: "/api/User/getRegisterConfig",
  GetSignInConfig: "/api/User/getSignInConfig",
  UserBalance: "/api/User/getUserBalance",
  NBGetHead: "/api/Account/getHead",
  NBGetUserInfo: "/api/Account/getUserInfo",
  NBGetUserInfoConfig: "/api/Account/getUserInfoConfig",
  NBGuestSignUp: "/api/User/guestSignUp",
  OAuthLoginAccess: "/api/User/oauthLoginAccess",
  ResetPassword: "/api/User/resetPassword", //重置密码
  SendSms: "/api/User/sendSms",
  Login: "/api/User/signIn", //登录
  Logout: "/api/User/signOut", //退出登录
  SignUp: "/api/User/signUp", //注册
  TokenCheck: "/api/User/tokenCheck",
  CheckWithdrawInfo: "/api/User/checkWithdrawInfo",
};

const serviceApi = {
  CustomerService: "/api/CustomerService/getCustomerService",
  FAQ: "/api/CustomerService/getFAQ",
};

export const redpackApi = {
  check: "/api/RedEnvelope/check",
  claim: "/api/RedEnvelope/claim",
  docs: "/api/RedEnvelope/getDocs",
  list: "/api/RedEnvelope/getList",
};

export const nbConfigApi = {
  GetSiteConfig: "/api/General/getSiteConfig",
  OAuthConfig: "/api/User/oauthLoginConfig",
  GetCurrencyList: "/api/user/getCurrencyList",
  GetSmsConfig: "/api/User/getSmsConfig",
};

export default {
  agentApi,
  fundsApi,
  userApi,
  serviceApi,
};
