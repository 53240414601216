import { requestPost } from "@/request/http/http";
import { ActivityApi, RouletteApi, ChallengeApi } from "@/request/http";
import { store } from "@/store";
import { ActivityInfo } from "@/interface";
import { getDeviceId } from "@/utils/util";

const activityLogic = {
  getSpinInfo: () => {
    return new Promise<any>((resolve) => {
      requestPost(
        RouletteApi.GetHome,
        null,
        (response) => {
          resolve(response);
        },
        () => {
          resolve(null);
        }
      );
    });
  },
  getFirstRechargeInfo: () => {
    return new Promise<any>((resolve) => {
      requestPost(
        ActivityApi.getActivityDiscountStatus,
        null,
        (response) => {
          const temp = response?.firstRechargeShowInActivity;
          if (temp?.status) {
            resolve(temp);
          } else {
            resolve(null);
          }
        },
        () => {
          resolve(null);
        }
      );
    });
  },
  getActivityCategory: (loading: boolean) => {
    requestPost(
      ActivityApi.ActivityCategory,
      {},
      (response) => {
        const res = response as any[] | null;
        if (res && res.length > 0) {
          res.forEach((item: any) => {
            item.text = `activity.ACTIVITY_LEFTTAB_${item.id}`;
          });
          store.commit("activities/saveleftActList", {
            list: res.filter((v: any) => v.id != "7" && v.id != "8"),
          });
        }
      },
      null,
      null,
      loading
    );
  },
  getActivityList: (category: string) => {
    return new Promise<any>((resolve) => {
      requestPost(
        ActivityApi.ActivityList,
        { categoryType: category },
        (response) => {
          resolve(response);
        },
        () => {
          resolve(null);
        }
      );
    });
  },
  getActivityInfo: (
    category: number,
    id: number,
    callback: (res: ActivityInfo[]) => void
  ) => {
    requestPost(
      ActivityApi.GetActivityInfo,
      { categoryType: category, id: id },
      (response) => {
        if (response) {
          const list = response as ActivityInfo[];
          callback(list);
        }
      }
    );
  },
  drawAllActivityAward: async (
    activityIds: string,
    callback: (res: number) => void
  ) => {
    requestPost(
      ActivityApi.ClaimedAll,
      { activityIds: activityIds, uuid: await getDeviceId() },
      (response) => {
        if (response) {
          callback(response.count);
        }
      },
      null,
      null,
      true
    );
  },
  // drawActivityAward: async (activityIds: string, callback: (res: number) => void) => {
  //   requestPost(
  //     ActivityApi.ClaimedByRecordId,
  //     { activityIds: activityIds, uuid: await getDeviceId() },
  //     (response) => {
  //       if (response) {
  //         callback(response.count);
  //       }
  //     }
  //   );
  // },
  // 获取活跃度宝箱列表
  getLivelinessInfo: () => {
    requestPost(
      ChallengeApi.GetLivelinessInfo,
      null,
      (response) => {
        const res = response as any | null;
        if (res == null) return;
        if (res?.list && res?.list.length > 0) {
          res?.list.unshift({
            target: "0",
          });
          store.commit("activities/saveAtivoBoxInfo", { data: res });
        }
      },
      () => {
        store.commit("activities/saveAtivoBoxInfo", { data: null });
      }
    );
  },
  // 获取任务左侧分类列表
  getTaskActList: (loading: boolean, callback: (res: string) => void) => {
    requestPost(
      ChallengeApi.GetChallengeCategory,
      null,
      (response) => {
        const res = response as any | null;
        if (res == null) return;
        callback(res.allChallenge);
        const taskIds = res?.allChallenge.split(",");
        const newTaskTabs: any[] = [];
        taskIds.forEach((item: string, index: number) => {
          if (item != "6") {
            newTaskTabs.push({
              taskType: item,
              taskName: res?.list[index],
            });
          }
        });
        store.commit("activities/saveTaskActList", { list: newTaskTabs });
      },
      null,
      null,
      loading
    );
  },
};
export default activityLogic;
